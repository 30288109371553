<template>
  <v-container fluid class="px-0 pb-0">
    <v-card tile flat class="px-2">
      <v-card-title class="d-flex align-center">
        Project Planning
        <span v-if="employeeName">&nbsp;- {{ employeeName }}</span>
        <v-spacer />
        <v-btn fab text small color="secondary" class="mr-6" @click="prev">
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          class="subtitle-1 font-weight-bold"
        >
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn fab text small color="secondary" class="ml-6" @click="next">
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
        <v-spacer />
        <div class="header-actions-container">
          <v-btn
            outlined
            color="secondary"
            class="border-1 border-radius-6"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn-toggle
            v-model="type"
            mandatory
            dense
            borderless
            class="border-1 border-radius-6"
            color="secondary"
            active-class="active-btn"
          >
            <v-btn
              v-for="actionBtn in calendarActionButtons"
              :key="actionBtn.value"
              text
              :class="{ 'secondary--text': type != actionBtn.value }"
              :value="actionBtn.value"
              width="80"
            >
              {{ actionBtn.label }}
            </v-btn>
          </v-btn-toggle>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-3 border-radius-6 custom-data-table-action-btn"
            @click.stop="openAddPlanningDialog"
          >
            <v-icon class="mr-1"> mdi-plus </v-icon>
            Add
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="fill-height border-radius-10">
          <v-col class="border-radius-10">
            <v-sheet height="600" class="border-radius-10">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                :event-overlap-mode="mode"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
                :first-interval="10"
                :interval-minutes="60"
                :interval-count="11"
                :weekdays="weekDays"
                class="custom-calendar"
              >
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                style="z-index: 50"
              >
                <v-card color="grey lighten-4" min-width="350px">
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-btn
                      icon
                      @click="
                        is_edit = true;
                        showEditDialog();
                      "
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      class="mr-2"
                      icon
                      @click.prevent="dialogDelete = true"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-expansion-panels
                      multiple
                      focusable
                      v-show="selectedEvent.tasks"
                      class="mb-3"
                    >
                      <v-expansion-panel
                        v-for="task in selectedEvent.tasks"
                        :key="task.task.task_id"
                      >
                        <v-expansion-panel-header>{{
                          task.task.name
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <span v-html="task.task.description"></span>
                          <br /><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row v-show="selectedEvent.tasks.length == 0">
                      <v-col cols="12" class="text-center">
                        No Tasks Available
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- confirmation dialog for deleting planning-->
    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Alert</v-card-title>
        <v-card-title class="subtitle-1 mb-4"
          >Are you sure you want to Remove this Planning?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-1" text @click="deletePlanning()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- for success message -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Dialog
      :isOpen="showAddEditPlanningDialog"
      @close="closeDialog"
      :action="!selectTaskDialog"
      :title="!is_edit ? `Add Planning` : `Edit Planning`"
      :save="saveUpdate"
    >
      <template>
        <v-text-field
          v-if="selectTaskDialog"
          v-model.lazy="search"
          v-debounce="500"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          class="mt-6 border-1"
          single-line
          solo
          flat
          dense
          hide-details
        ></v-text-field>
      </template>

      <template>
        <template>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-select
                :items="assignProjects"
                label="Project Name"
                dense
                item-text="name"
                item-value="project_id"
                outlined
                v-model="employee.project_id"
                :error="errors.project_id != null"
                :error-messages="errors.project_id"
              ></v-select>
            </v-col>
            <v-col cols="12" class="dataTimePiker py-0">
              <v-datetime-picker
                label="Start Date And Time"
                v-model="employee.start_datetime"
                v-show="employee.project_id"
                class="ma-0"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
              <span
                v-if="errors.start_datetime != null"
                class="red--text caption"
              >
                {{ errors.start_datetime }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="employee.end_datetime"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-show="employee.project_id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="employee.end_datetime"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employee.end_datetime"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(employee.end_datetime)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span
                v-if="errors.end_datetime != null"
                class="red--text caption"
              >
                {{ errors.end_datetime }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="Hours"
                placeholder="Hours"
                outlined
                dense
                v-model="employee.hours"
                name="hours"
                class="pa-0"
                :error="errors.hours != null"
                :error-messages="errors.hours"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                outlined
                :items="filteredTasks"
                item-text="name"
                item-value="task_id"
                chips
                clearable
                dense
                deletable-chips
                multiple
                small-chips
                label="Select task"
                v-model="employee.task_id"
              >
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" class="pt-0 pb-4">
              <v-btn
                @click="selectTaskDialog = true"
                class="full-width border-radius-6 py-4"
                color="secondary"
                text
                outlined
                :disabled="!employee.project_id"
                :loading="loadingTasks"
              >
                Select Tasks
              </v-btn>
            </v-col> -->
          </v-row>
        </template>
      </template>
      <!-- <template> -->
      <!-- <template v-if="selectTaskDialog">
          <v-btn
            class="full-width border-radius-6 py-5"
            depressed
            color="primary"
            @click.stop="
              selectTaskDialog = false;
              search = '';
            "
            :disabled="loading"
          >
            Save
          </v-btn>
        </template> -->
      <!-- <template v-else>
          <v-btn
            class="full-width border-radius-6 py-5"
            depressed
            color="primary"
            @click="saveUpdate()"
            :disabled="loading"
          >
            Save
          </v-btn>
        </template> -->
      <!-- </template> -->
    </Dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import debounce from "v-debounce";
import Dialog from "../common/basic/Dialog.vue";

export default {
  data: () => ({
    is_edit: false,
    planning: [],
    snackbar: false,
    snackbarError: false,
    message: "",
    title: "",
    focus: "",
    type: "day",
    mode: "column",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    weekDays: [1, 2, 3, 4, 5, 6],
    selectedEvent: {
      tasks: [],
    },
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [],
    assignProjects: [],
    employee: {
      project_id: "",
      start_datetime: new Date(),
      end_datetime: moment(new Date()).format("YYYY-MM-DD"),
      hours: "",
      task_id: [1, 3, 4, 5],
    },
    loading: false,
    showAddEditPlanningDialog: false,
    members: [],
    types: ["month", "week", "day"],
    categories: [],
    menu2: false,
    errors: {},
    tasks: [],
    selectedDay: "",
    dialogDelete: false,
    searchResults: [],
    loadingTasks: false,
    selectTaskDialog: false,
    search: "",
    headers: [
      {
        text: "",
        value: "name",
        sortable: false,
      },
    ],
    calendarActionButtons: [
      {
        label: "Day",
        value: "day",
      },
      {
        label: "Week",
        value: "week",
      },
      {
        label: "Month",
        value: "month",
      },
    ],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.showPlanning();
    this.getProjectList();
  },
  computed: {
    employeeName: function () {
      return this.planning.length ? this.planning[0].employee?.name : "";
    },
    filteredTasks: function () {
      // filter tasks based on the search
      if (this.search && Array.isArray(this.searchResults)) {
        const regExp = new RegExp(this.search.toLowerCase(), "ig");

        return this.searchResults.filter(
          (task) => task.name.toLowerCase().search(regExp) != -1
        );
      }

      return this.searchResults;
    },
  },
  watch: {
    employee: function () {
      // this.employee.start_datetime = moment(this.employee.start_datetime).format('YYYY-MM-DD')
      this.employee.end_datetime = moment(this.employee.end_datetime).format(
        "YYYY-MM-DD"
      );
    },
    type: function () {
      if (this.type != "day") {
        this.employee.start_datetime = new Date();
      }
    },
    "employee.project_id": function () {
      // this.employee.task_id = [];
      this.getAssignedTasks();
    },
  },
  components: {
    Dialog,
  },
  methods: {
    getAssignedTasks() {
      this.loadingTasks = true;

      this.$axios
        .post("getTasks", {
          project_id: this.employee.project_id,
        })
        .then((response) => {
          let data = response.data;

          this.tempTasks = [];

          if (data.success) {
            this.searchResults = data.tasks;
          } else {
            this.error = true;
            this.message = data.message;
            this.searchResults = [];
          }
        })
        .finally(() => {
          this.loadingTasks = false;
        });
    },
    showPlanning() {
      let _self = this;
      _self.$axios
        .post("showPlanning", {
          employee_id: _self.$route.params.id,
        })
        .then((res) => {
          let data = res.data;
          if (data.success) {
            _self.planning = data.planning;
            _self.updateRange();
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    showEditDialog() {
      let selectedEvent = this.selectedEvent;
      let _self = this;
      _self.employee.project_id = selectedEvent.project_id;
      _self.employee.start_datetime = new Date(selectedEvent.start);
      _self.employee.end_datetime = moment(selectedEvent.end).format(
        "YYYY-MM-DD"
      );
      _self.employee.hours = selectedEvent.hours;

      _self.employee.task_id = selectedEvent.tasks.map((task) => {
        return task.task_id;
      });
      _self.showAddEditPlanningDialog = true;
    },
    deletePlanning() {
      let _self = this;

      _self.$axios.delete("/planning/" + _self.selectedEvent.id).then((res) => {
        let data = res.data;

        if (data.success) {
          _self.selectedOpen = false;
          _self.dialogDelete = false;
          _self.showPlanning();
        } else {
          _self.snackbarError = true;
          _self.message = data.message;
        }
      });
    },
    getProjectList() {
      let _self = this;

      _self.$axios
        .post("employeeProjects", {
          employee_id: _self.$route.params.id,
        })
        .then((res) => {
          let data = res.data;
          if (data.success) {
            _self.assignProjects = data.projects;
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    closeDialog() {
      this.showAddEditPlanningDialog = false;
      this.selectTaskDialog = false;
      this.employee.project_id = "";

      if (this.type != "day") {
        this.selectedDay = "";
        this.employee.start_datetime = new Date();
        this.employee.end_datetime = moment(new Date()).format("YYYY-MM-DD");
      } else {
        this.employee.start_datetime = new Date(this.selectedDay);
        this.employee.end_datetime = moment(new Date()).format("YYYY-MM-DD");
      }
      this.employee.hours = "";
      this.employee.task_id = [];
      this.errors = {};
    },
    save() {
      let _self = this;
      _self.loading = true;

      let valid_start_datetime = new Date(
        _self.employee.start_datetime
      ).setHours("10", "0", "0");
      let valid_end_datetime = new Date(_self.employee.start_datetime).setHours(
        "21",
        "0",
        "0"
      );
      let start_date = new Date(_self.employee.start_datetime);

      let month = Number(start_date.getMonth() + 1);
      month = month < 10 ? "0" + month : month;

      let dt = start_date.getDate();
      dt = dt < 10 ? "0" + dt : dt;

      _self.$axios
        .post("planning", {
          project_id: _self.employee.project_id,
          employee_id: _self.$route.params.id,
          valid_start_datetime: valid_start_datetime,
          valid_end_datetime: valid_end_datetime,
          start_datetime: _self.employee.start_datetime,
          end_datetime: _self.employee.end_datetime,
          hours: _self.employee.hours,
          task_id: _self.employee.task_id,
          start_date: start_date.getFullYear() + "-" + month + "-" + dt,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          if (data.success) {
            _self.closeDialog();
            _self.getProjectList();
            _self.showPlanning();
            _self.showAddEditPlanningDialog = false;
            _self.errors.start_datetime = "";
          } else {
            _self.errors = {};
            if (data.errors) {
              for (let i = 0; i < data.errors.length; i++) {
                if (data.errors[i].field == "start_datetime") {
                  if (
                    data.errors[i].invalid_start_datetime &&
                    data.errors[i].invalid_end_datetime
                  ) {
                    data.errors[i].message +=
                      moment(data.errors[i].invalid_start_datetime).format(
                        "Do MMMM YYYY hh:mm A"
                      ) +
                      " - " +
                      moment(data.errors[i].invalid_end_datetime).format(
                        "hh:mm A"
                      );
                  }
                }
                _self.errors[data.errors[i].field] = data.errors[i].message;
              }
            } else {
              _self.showAddEditPlanningDialog = true;
              _self.members = data.message;
            }
          }
        });
    },
    update() {
      let _self = this;
      _self.loading = true;

      let valid_start_datetime = new Date(
        _self.employee.start_datetime
      ).setHours("10", "0", "0");
      let valid_end_datetime = new Date(_self.employee.start_datetime).setHours(
        "21",
        "0",
        "0"
      );
      let start_date = new Date(_self.employee.start_datetime);

      let month = Number(start_date.getMonth() + 1);
      month = month < 10 ? "0" + month : month;

      let dt = start_date.getDate();
      dt = dt < 10 ? "0" + dt : dt;

      _self.$axios
        .post("/updateplanning", {
          id: _self.selectedEvent.id,
          project_id: _self.employee.project_id,
          employee_id: _self.$route.params.id,
          valid_start_datetime: valid_start_datetime,
          valid_end_datetime: valid_end_datetime,
          start_datetime: _self.employee.start_datetime,
          end_datetime: _self.employee.end_datetime,
          hours: _self.employee.hours,
          task_id: _self.employee.task_id,
          start_date: start_date.getFullYear() + "-" + month + "-" + dt,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          if (data.success) {
            _self.closeDialog();
            _self.events = [];
            _self.getProjectList();
            _self.showPlanning();
            _self.showAddEditPlanningDialog = false;
          } else {
            _self.errors = {};
            if (data.errors) {
              for (let i = 0; i < data.errors.length; i++) {
                if (data.errors[i].field == "start_datetime") {
                  if (
                    data.errors[i].invalid_start_datetime &&
                    data.errors[i].invalid_end_datetime
                  ) {
                    data.errors[i].message +=
                      moment(data.errors[i].invalid_start_datetime).format(
                        "Do MMMM YYYY hh:mm A"
                      ) +
                      " - " +
                      moment(data.errors[i].invalid_end_datetime).format(
                        "hh:mm A"
                      );
                  }
                }
                _self.errors[data.errors[i].field] = data.errors[i].message;
              }
            } else {
              _self.showAddEditPlanningDialog = true;
              _self.members = data.message;
            }
          }
        });
    },
    saveUpdate() {
      if (this.is_edit) this.update();
      else this.save();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      const events = [];
      let _self = this;
      for (let i = 0; i < _self.planning.length; i++) {
        const first = new Date(this.planning[i].start_datetime);
        const second = new Date(this.planning[i].end_datetime);

        events.push({
          name: this.planning[i].project.name,
          start: first,
          end: second,
          project_id: this.planning[i].project_id,
          id: this.planning[i].id,
          hours: this.planning[i].hours,
          color: this.planning[i].project.project_color_hex,
          tasks: this.planning[i].tasks,
          timed: true,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    openAddPlanningDialog() {
      this.is_edit = false;
      this.showAddEditPlanningDialog = true;
    },
  },
  directives: {
    debounce,
  },
};
</script>

<style scoped>
@import "../../assets/css/custom-calendar.css";

.custom-checkbox-input label {
  font-size: 15px !important;
  color: var(--secondary-color);
}
</style>

<style lang="scss">
.dataTimePiker .v-input__slot {
  border: 1px solid #e9edf7 !important;

  border-radius: 10px !important;
}
.dataTimePiker:hover {
  .v-input__slot {
    position: relative;
    border: 1px solid #000000 !important;
    z-index: 0;

    border-radius: 10px !important;
  }
}

.dataTimePiker .v-input__slot {
  .v-label--active {
    z-index: 100;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
.dataTimePiker
  .theme--light.v-text-field
  .v-input__control
  .v-input__slot:before {
  border: none !important;
}
.dataTimePiker {
  .theme--light.v-text-field .v-input__control .v-input__slot:after {
    border: none !important;
  }
  .v-label {
    top: 10px !important;
    left: 11px !important;
    right: auto !important;
    position: absolute !important;
    z-index: 100;
    background-color: rgb(255, 255, 255) !important;
  }
  .v-input input {
    margin-left: 10px !important;
    min-height: 40px !important;
  }
}
</style>
